import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2400b353 = () => interopDefault(import('../pages/eskuvo/index.vue' /* webpackChunkName: "pages/eskuvo/index" */))
const _090d556b = () => interopDefault(import('../pages/galeria/index.vue' /* webpackChunkName: "pages/galeria/index" */))
const _00433998 = () => interopDefault(import('../pages/idopontfoglalas/index.vue' /* webpackChunkName: "pages/idopontfoglalas/index" */))
const _267858ae = () => interopDefault(import('../pages/kapcsolat/index.vue' /* webpackChunkName: "pages/kapcsolat/index" */))
const _7ef18d6c = () => interopDefault(import('../pages/palyazat/index.vue' /* webpackChunkName: "pages/palyazat/index" */))
const _674acb40 = () => interopDefault(import('../pages/szallas/index.vue' /* webpackChunkName: "pages/szallas/index" */))
const _34960351 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _c787ca80 = () => interopDefault(import('../pages/admin/calendar/index.vue' /* webpackChunkName: "pages/admin/calendar/index" */))
const _fe024022 = () => interopDefault(import('../pages/admin/editor/index.vue' /* webpackChunkName: "pages/admin/editor/index" */))
const _6b8cdabd = () => interopDefault(import('../pages/admin/login/index.vue' /* webpackChunkName: "pages/admin/login/index" */))
const _2a750982 = () => interopDefault(import('../pages/admin/old-dashboard.vue' /* webpackChunkName: "pages/admin/old-dashboard" */))
const _44218a2e = () => interopDefault(import('../pages/admin/reservations/index.vue' /* webpackChunkName: "pages/admin/reservations/index" */))
const _6c6afe9c = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _346022c0 = () => interopDefault(import('../pages/a-kastelyrol/degenfeld-csalad/_name.vue' /* webpackChunkName: "pages/a-kastelyrol/degenfeld-csalad/_name" */))
const _3ee34cc5 = () => interopDefault(import('../pages/a-kastelyrol/_panel.vue' /* webpackChunkName: "pages/a-kastelyrol/_panel" */))
const _77002223 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/eskuvo",
    component: _2400b353,
    name: "eskuvo"
  }, {
    path: "/galeria",
    component: _090d556b,
    name: "galeria"
  }, {
    path: "/idopontfoglalas",
    component: _00433998,
    name: "idopontfoglalas"
  }, {
    path: "/kapcsolat",
    component: _267858ae,
    name: "kapcsolat"
  }, {
    path: "/palyazat",
    component: _7ef18d6c,
    name: "palyazat"
  }, {
    path: "/szallas",
    component: _674acb40,
    name: "szallas"
  }, {
    path: "/test",
    component: _34960351,
    name: "test"
  }, {
    path: "/admin/calendar",
    component: _c787ca80,
    name: "admin-calendar"
  }, {
    path: "/admin/editor",
    component: _fe024022,
    name: "admin-editor"
  }, {
    path: "/admin/login",
    component: _6b8cdabd,
    name: "admin-login"
  }, {
    path: "/admin/old-dashboard",
    component: _2a750982,
    name: "admin-old-dashboard"
  }, {
    path: "/admin/reservations",
    component: _44218a2e,
    name: "admin-reservations"
  }, {
    path: "/admin/users",
    component: _6c6afe9c,
    name: "admin-users"
  }, {
    path: "/a-kastelyrol/degenfeld-csalad/:name?",
    component: _346022c0,
    name: "a-kastelyrol-degenfeld-csalad-name"
  }, {
    path: "/a-kastelyrol/:panel?",
    component: _3ee34cc5,
    name: "a-kastelyrol-panel"
  }, {
    path: "/",
    component: _77002223,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
