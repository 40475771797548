import Vue from 'vue'

window._hasScrollListener = false
const pageHeight = (document.body.scrollHeight - window.innerHeight)
if (!window._hasScrollListener) {
  window.addEventListener('scroll', () => {
    // console.log(window.scrollY / pageHeight)
    document.body.style.setProperty('--scroll', window.scrollY /* / pageHeight */)
  }, false)
  window._hasScrollListener = true
}

function calculateAnimationAttributes (el) {
  if (el !== null && el !== undefined && el) {
    let parent = el
    // console.log(el)
    // console.log(parent)
    while (parent && parent.tagName !== 'BODY') {
      itemOffsetTop = parent.offsetTop > itemOffsetTop ? parent.offsetTop : itemOffsetTop
      parent = parent.parentNode
    }
    if (itemOffsetTop > pageHeight) {
      itemOffsetTop = pageHeight
    } else if (itemOffsetTop < 0) {
      itemOffsetTop = 0
    }
    el.classList.add('_animate')
    const offset = itemOffsetTop - window.innerHeight
    el.setAttribute('style', `
      animation-fill-mode: both;
      animation-play-state: paused;
      animation-iteration-count: 1;
      animation-duration: 1s;
      animation-delay: calc( (((var(--scroll) - ${offset}) / ${window.innerHeight}) ) * -1s);
    `)
    // * ${pageHeight}
  }
}

let itemOffsetTop = 0
Vue.directive('animate', {
  bind: (el) => {
    calculateAnimationAttributes(el)
  }
})
