//
//
//
//
//
//
//
//
//
//

import navbar from '~/components/navbar'
import footerbar from '~/components/footerbar'
export default {
  components: {
    navbar,
    footerbar
  }
}
