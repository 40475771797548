export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Footerbar = () => import('../../components/footerbar.vue' /* webpackChunkName: "components/footerbar" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const CommonDropbox = () => import('../../components/common/dropbox.vue' /* webpackChunkName: "components/common-dropbox" */).then(c => wrapFunctional(c.default || c))
export const CommonHero = () => import('../../components/common/hero.vue' /* webpackChunkName: "components/common-hero" */).then(c => wrapFunctional(c.default || c))
export const CommonIconBox = () => import('../../components/common/icon-box.vue' /* webpackChunkName: "components/common-icon-box" */).then(c => wrapFunctional(c.default || c))
export const CommonModal = () => import('../../components/common/modal.vue' /* webpackChunkName: "components/common-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonWhiteBox = () => import('../../components/common/white-box.vue' /* webpackChunkName: "components/common-white-box" */).then(c => wrapFunctional(c.default || c))
export const ModalsEditorModal = () => import('../../components/modals/editor-modal.vue' /* webpackChunkName: "components/modals-editor-modal" */).then(c => wrapFunctional(c.default || c))
export const PagesFaqSection = () => import('../../components/pages/FaqSection.vue' /* webpackChunkName: "components/pages-faq-section" */).then(c => wrapFunctional(c.default || c))
export const PagesGallerySection = () => import('../../components/pages/GallerySection.vue' /* webpackChunkName: "components/pages-gallery-section" */).then(c => wrapFunctional(c.default || c))
export const PagesImgSection = () => import('../../components/pages/ImgSection.vue' /* webpackChunkName: "components/pages-img-section" */).then(c => wrapFunctional(c.default || c))
export const PagesOneColumnSection = () => import('../../components/pages/OneColumnSection.vue' /* webpackChunkName: "components/pages-one-column-section" */).then(c => wrapFunctional(c.default || c))
export const PagesPhotoPromo = () => import('../../components/pages/PhotoPromo.vue' /* webpackChunkName: "components/pages-photo-promo" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonPagination = () => import('../../components/admin/common/pagination.vue' /* webpackChunkName: "components/admin-common-pagination" */).then(c => wrapFunctional(c.default || c))
export const AdminNavbarsSideNavbar = () => import('../../components/admin/navbars/SideNavbar.vue' /* webpackChunkName: "components/admin-navbars-side-navbar" */).then(c => wrapFunctional(c.default || c))
export const AdminPageBase = () => import('../../components/admin/page/PageBase.vue' /* webpackChunkName: "components/admin-page-base" */).then(c => wrapFunctional(c.default || c))
export const AdminPageHeader = () => import('../../components/admin/page/PageHeader.vue' /* webpackChunkName: "components/admin-page-header" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolBekCsalad = () => import('../../components/pages/a-kastelyrol/bek-csalad.vue' /* webpackChunkName: "components/pages-a-kastelyrol-bek-csalad" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolDegenfeldCsalad = () => import('../../components/pages/a-kastelyrol/degenfeld-csalad.vue' /* webpackChunkName: "components/pages-a-kastelyrol-degenfeld-csalad" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolKastely = () => import('../../components/pages/a-kastelyrol/kastely.vue' /* webpackChunkName: "components/pages-a-kastelyrol-kastely" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolPark = () => import('../../components/pages/a-kastelyrol/park.vue' /* webpackChunkName: "components/pages-a-kastelyrol-park" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolSzemelveny = () => import('../../components/pages/a-kastelyrol/szemelveny.vue' /* webpackChunkName: "components/pages-a-kastelyrol-szemelveny" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexRooms = () => import('../../components/pages/index/rooms.vue' /* webpackChunkName: "components/pages-index-rooms" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexServices = () => import('../../components/pages/index/services.vue' /* webpackChunkName: "components/pages-index-services" */).then(c => wrapFunctional(c.default || c))
export const RxtRecomponentReOverlayLoader = () => import('../../components/rxt/recomponent/ReOverlayLoader.vue' /* webpackChunkName: "components/rxt-recomponent-re-overlay-loader" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReCheckbox = () => import('../../components/rxt/reform/ReCheckbox.vue' /* webpackChunkName: "components/rxt-reform-re-checkbox" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReClock = () => import('../../components/rxt/reform/ReClock.vue' /* webpackChunkName: "components/rxt-reform-re-clock" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReDateRange = () => import('../../components/rxt/reform/ReDateRange.vue' /* webpackChunkName: "components/rxt-reform-re-date-range" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReDraggable = () => import('../../components/rxt/reform/ReDraggable.vue' /* webpackChunkName: "components/rxt-reform-re-draggable" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReInput = () => import('../../components/rxt/reform/ReInput.vue' /* webpackChunkName: "components/rxt-reform-re-input" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReSelect = () => import('../../components/rxt/reform/ReSelect.vue' /* webpackChunkName: "components/rxt-reform-re-select" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReTextarea = () => import('../../components/rxt/reform/ReTextarea.vue' /* webpackChunkName: "components/rxt-reform-re-textarea" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonFormRxtInput = () => import('../../components/admin/common/form/RxtInput.vue' /* webpackChunkName: "components/admin-common-form-rxt-input" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonFormRxtTextarea = () => import('../../components/admin/common/form/RxtTextarea.vue' /* webpackChunkName: "components/admin-common-form-rxt-textarea" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonUtilitiesRxtCalendar = () => import('../../components/admin/common/utilities/RxtCalendar.vue' /* webpackChunkName: "components/admin-common-utilities-rxt-calendar" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonUtilitiesRxtModal = () => import('../../components/admin/common/utilities/RxtModal.vue' /* webpackChunkName: "components/admin-common-utilities-rxt-modal" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonUtilitiesRxtTable = () => import('../../components/admin/common/utilities/RxtTable.vue' /* webpackChunkName: "components/admin-common-utilities-rxt-table" */).then(c => wrapFunctional(c.default || c))
export const PagesADegenfeldekEredete = () => import('../../components/pages/a-kastelyrol/degenfeld-csalad/a-degenfeldek-eredete.vue' /* webpackChunkName: "components/pages-a-degenfeldek-eredete" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolDegenfeldAnna = () => import('../../components/pages/a-kastelyrol/degenfeld-csalad/degenfeld-anna.vue' /* webpackChunkName: "components/pages-a-kastelyrol-degenfeld-anna" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolDegenfeldBerta = () => import('../../components/pages/a-kastelyrol/degenfeld-csalad/degenfeld-berta.vue' /* webpackChunkName: "components/pages-a-kastelyrol-degenfeld-berta" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolDegenfeldEmma = () => import('../../components/pages/a-kastelyrol/degenfeld-csalad/degenfeld-emma.vue' /* webpackChunkName: "components/pages-a-kastelyrol-degenfeld-emma" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolDegenfeldIlona = () => import('../../components/pages/a-kastelyrol/degenfeld-csalad/degenfeld-ilona.vue' /* webpackChunkName: "components/pages-a-kastelyrol-degenfeld-ilona" */).then(c => wrapFunctional(c.default || c))
export const PagesAKastelyrolDegenfeldImreKristof = () => import('../../components/pages/a-kastelyrol/degenfeld-csalad/degenfeld-imre-kristof.vue' /* webpackChunkName: "components/pages-a-kastelyrol-degenfeld-imre-kristof" */).then(c => wrapFunctional(c.default || c))
export const RxtReformPartialsCalendarMonth = () => import('../../components/rxt/reform/partials/CalendarMonth.vue' /* webpackChunkName: "components/rxt-reform-partials-calendar-month" */).then(c => wrapFunctional(c.default || c))
export const RxtReformPartialsDatePicker = () => import('../../components/rxt/reform/partials/DatePicker.vue' /* webpackChunkName: "components/rxt-reform-partials-date-picker" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
