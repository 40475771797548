export const state = () => {
  return {
    siteData: {}
  }
}

export const actions = {
  async init (store, context) {
    const querySnapshot = await this.$fire.firestore.collection('site').get()

    querySnapshot.forEach((doc) => {
      store.commit('addSiteData', { id: doc.id, data: doc.data() })
    })
  }
}

export const mutations = {
  addSiteData (state, { id, data }) {
    state.siteData[id] = data
  }
}

export const getters = {
  __: (state) => {
    return (id, path) => {
      const object = Object.keys(state.siteData).find((key) => {
        return state.siteData[key].id === id && state.siteData[key].path === path
      })

      if (object) {
        return state.siteData[object].data
      }

      return null
    }
  }
}
