//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      menuOpen: false,
      menuItems: [
        {
          name: 'Főoldal',
          url: '/'
        },
        {
          name: 'A kastélyról',
          url: '/a-kastelyrol/kastelytortenet'
        },
        {
          name: 'Esküvő',
          url: '/eskuvo'
        },
        {
          name: 'Galéria',
          url: '/galeria'
        },
        {
          name: 'Szállás',
          url: '/szallas'
        },
        {
          name: 'Időpontfoglalás',
          url: '/idopontfoglalas'
        },
        {
          name: 'Pályázat',
          url: '/palyazat'
        },
        {
          name: 'Kapcsolat',
          url: '/kapcsolat'
        }
      ]
    }
  },
  beforeMount () {
    if (process.browser) {
      window.addEventListener('click', this.close)
    }
  },
  beforeDestroy () {
    if (process.browser) {
      window.removeEventListener('click', this.close)
    }
  },
  methods: {
    close (e) {
      // if (!this.$el.contains(e.target) || e.target.tagName !== 'A') {
      //   this.menuOpen = false
      // } else
      if (this.$el.contains(e.target) && (e.target.tagName === 'A' || e.target.tagName === 'IMG')) {
        this.menuOpen = false
      }
    }
  }
}
