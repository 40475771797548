export const state = () => ({

})

export const actions = {
  async nuxtServerInit (store, context) {
    await store.dispatch('site/init', context)
    return await store.dispatch('user/init', context)
  }
}
