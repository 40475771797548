export default function (context) {
  if (
    !context.store.getters['user/isLoggedIn'] &&
    !context.store.getters['user/isAdmin'] &&
    !context.store.getters['user/isRole']('editor') &&
    !context.store.getters['user/isRole']('porta')
  ) {
    return context.redirect('/')
  }
}
