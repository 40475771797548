//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      menu: [
        {
          name: 'Reservations',
          url: '/admin/reservations',
          icon: '/img/icons/calendar.svg',
          roles: ['admin']
        },
        {
          name: 'Calendar',
          url: '/admin/calendar',
          icon: '/img/icons/calendar.svg',
          roles: ['porta', 'admin']
        },
        {
          name: 'Site Editor',
          url: '/admin/editor',
          icon: '/img/icons/text.svg',
          roles: ['editor', 'admin']
        },
        {
          name: 'Users',
          url: '/admin/users',
          icon: '/img/icons/user.svg',
          roles: ['admin']
        },
        {
          name: 'Logout',
          callback: this.logout,
          icon: '/img/icons/logout.svg'
        }
      ]
    }
  },
  computed: {
    menuToShow () {
      return this.menu.filter((item) => {
        if (!item.roles) {
          return true
        }

        const keys = Object.keys(this.$store.getters['user/getUser'].claims)

        let contains = false
        for (let i = 0; i < keys.length; i++) {
          if (item.roles.includes(keys[i])) {
            contains = true
            break
          }
        }
        return contains
      })
    }
  }
}
