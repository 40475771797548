import Vue from 'vue'

window._hasintersectionObserver = false
if (!window._hasintersectionObserver) {
  window._intersectionObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('enter')
          // window._intersectionObserver.unobserve(entry)
        } else {
          entry.target.classList.remove('enter')
        }
      })
    }
  )
}

Vue.directive('trigger', {
  bind: (el) => {
    el.classList.add('before-enter')
    window._intersectionObserver.observe(el)
  }
})
