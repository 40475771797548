//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    title: {
      required: true,
      type: String
    },
    message: {
      required: true,
      type: String
    },
    variant: {
      type: String
    },
    offset: {
      type: Number
    }
  }
}
